<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    mounted() {},
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
/* 滚动条样式 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #e5e5e5;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
}
@import url('@/assets/iconfont/iconfont.css');
</style>
