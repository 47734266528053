import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import request from './../api/index.js';
import socket from './common/websockt';
import deConfig from './common/config';
import { createPinia, PiniaVuePlugin } from 'pinia';
import 'default-passive-events';
import vuePicturePreview from 'vue-picture-preview';

import '@/icons';

Vue.component('Previewer', vuePicturePreview);
Vue.use(ElementUI);
Vue.use(request);
// Vue.use(socket);
Vue.use(socket);
Vue.config.productionTip = false;
Vue.prototype.$request = request;
Vue.prototype.$socket = socket;
Vue.prototype.$deConfig = deConfig;

// 创建一个事件总线用于弹窗通讯
Vue.prototype.$eventBus = new Vue();

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
    router,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
