<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="`#icon-${iconName}`" />
    </svg>
</template>

<script>
export default {
    props: {
        svgClass: {
            type: String,
            default: ''
        },
        iconName: {
            type: String,
            require: true,
        }
    }
}
</script>

<style>
</style>