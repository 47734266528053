/**
 * develop：开发  trial：体验版  release：正式版  newcall: 通用配置（只需要配置对应网段，不需要配置对应的 域名）
 * scyb：宜宾版本 scgz：甘孜版本 scgz_bg：甘孜版本(办公网络版本) scdz：达州版本 scbz: 巴中版本  scsnj: 四川省女子监狱 scmz: 眉州监狱
 */
const version = 'release';
const miidVersion = 'release';
/** 插话白名单 */
export const interjectList = ['develop', 'release', 'scbz', 'scsnj'];
/** 监听审核备注白名单（审核备注是否必填） */
export const monitorRemarkList = ['develop', 'scbz'];
// 赋值环境变量设置的版本
if (process.env.BRAND_VERSION) {
    version = process.env.BRAND_VERSION;
}

const dev = {
    version,
    isOpenInterject: interjectList.includes(version), // 是否开启插话功能
    baseUrl: getApiUrl(version),
    fileurl: getImgUrl(version),
    callUrl: getCallUrl(version),
    interjectUrl: getInterjectUrl(version),
    merchant: {
        miid: getMIID(miidVersion), //商户id
    },
    socket: {
        wsUrl: getSocketWs(version),
        isOpenHeart: false, //是否开启心跳
    },
    response: {
        //自定义响应字段
        statusName: 'code', //数据状态的字段名称
        statusCode: {
            ok: 0, //数据状态一切正常的状态码
            error: 1001, //数据返回错误状态
            logout: 1002, //登录状态失效的状态码
            noAuthority: 1003, //未授权
        },
        msgName: 'msg', //状态信息的字段名称
        dataName: 'data', //数据详情的字段名称
    },
};

// 获取接口api
function getApiUrl(version) {
    switch (version) {
        case 'develop': // 开发
            return 'http://192.168.2.30:8011/';
        case 'trial': // 体验
            return 'http://192.168.2.30:8011/';
        case 'release': // 正式版
            return 'https://qy.xnjx.net/monitor/';
        case 'scyb': // 宜宾正式版
            return 'http://10.8.62.20:81/monitor/';
        case 'scgz': // 甘孜正式版
            return 'http://10.3.180.20:8001/monitor/';
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return 'http://10.2.64.221:8001/monitor/';
        case 'scdz': // 达州正式版
            return 'http://10.2.51.10:81/monitor/';
        case 'scbz': // 巴中正式版
            return 'http://10.2.6.48:81/monitor/';
        case 'scsnj': // 省女子监狱
            return '/monitor/';
        case 'scmz': // 眉州监狱
            return 'http://192.168.2.42:81/monitor/';
        case 'newcall': // 通用配置
            return '/monitor/';
        default: // 默认
            return 'https://qy.xnjx.net/monitor/';
    }
}
// 获取图片api
function getImgUrl(version) {
    switch (version) {
        case 'develop': // 开发
            return 'http://192.168.2.31:8102';
        case 'trial': // 体验
            return 'http://192.168.2.31:8102';
        case 'release': // 正式版
            return 'https://qy.xnjx.net/img';
        case 'scyb': // 宜宾正式版
            return 'https://10.8.62.20:81/img';
        case 'scgz': // 甘孜正式版
            return 'http://10.3.180.20:8001/img';
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return 'http://10.2.64.221:8001/img';
        case 'scdz': // 达州正式版
            return 'http://10.2.51.10:81/img';
        case 'scbz': // 巴中正式版
            return 'http://10.2.6.48:81/img';
        case 'scsnj': // 省女子监狱
            return '/img';
        case 'scmz': // 眉州监狱
            return 'http://192.168.2.42:81/img';
        case 'newcall': // 通用配置
            return '/file';
        default: // 默认
            return 'https://qy.xnjx.net/img';
    }
}

// 获取通话录音api
function getCallUrl(version) {
    switch (version) {
        case 'develop': // 开发
            return 'https://qy.xnjx.net';
        case 'trial': // 体验
            return 'http://125.64.210.40:21015';
        case 'release': // 正式版
            return 'http://125.64.210.40:21015';
        case 'scyb': // 宜宾正式版
            return 'http://10.8.62.20:81/record/play';
        case 'scgz': // 甘孜正式版
            return 'http://10.3.180.20:8001/record/play/';
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return 'http://10.2.64.221:8001/record/play/';
        case 'scdz': // 达州正式版
            return 'http://10.2.51.10:81/record/play/';
        case 'scbz': // 巴中正式版
            return 'http://10.2.6.48:81/record/play/';
        case 'scsnj': // 省女子监狱
            return '/record/play/';
        case 'scmz': // 眉州监狱
            return 'http://192.168.2.42:81/record/play/';
        case 'newcall': // 通用配置
            return '/record/play/';
        default: // 默认
            return 'http://125.64.210.40:21015';
    }
}

// 获取商户ID
function getMIID(version) {
    switch (version) {
        case 'develop': // 开发 MIID 从【0BE10F61-3865-47F1-BBE1-9512B762B2EF】更新为【FF124A2C-5E7F-4BCA-B8B7-BA24EB576159】
            return 'FF124A2C-5E7F-4BCA-B8B7-BA24EB576159';
        case 'trial': // 体验 MIID 从【0BE10F61-3865-47F1-BBE1-9512B762B2EF】更新为【FF124A2C-5E7F-4BCA-B8B7-BA24EB576159】
            return 'FF124A2C-5E7F-4BCA-B8B7-BA24EB576159';
        case 'release': // 正式版
            return 'FF124A2C-5E7F-4BCA-B8B7-BA24EB576159';
        case 'scyb': // 宜宾正式版
            return '0be10f61-3865-47f1-bbe1-9512b762b2ef';
        case 'scgz': // 甘孜正式版
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return '5ffe71c6-6244-427c-a2dd-c8cdf83fee4f';
        case 'scdz': // 达州正式版
            return 'B0E1088E-3CE6-44FB-915B-CB909D3A6E99';
        case 'scbz': // 巴中正式版
            return 'DF2108F5-12D4-4E81-AA01-EB808D54C13E';
        case 'scsnj': // 省女子监狱
            return 'CE363105-F39E-4331-BCE9-E5E10BFE15FD';
        case 'scmz': // 眉州监狱
            return '10FB1057-E9E1-4ED8-BFE1-122B299C29DB';
        default: // 默认
            return 'EAACA281-1BBB-4F0D-928D-59C9B7C872F0';
    }
}

// 获取socket地址
function getSocketWs(version) {
    const host = window.location.host;
    let origin = window.location.origin;
    let suffix = '';
    if (/^https/.test(origin)) {
        suffix = 'wss';
    } else if (/^http/.test(origin)) {
        suffix = 'ws';
    }
    origin = origin.replace(/^http(s)?/, suffix);
    switch (version) {
        case 'develop': // 开发
            return 'ws://192.168.2.31:8103/';
        case 'trial': // 体验
            return 'ws://192.168.2.31:8103/';
        case 'release': // 正式版
            return 'wss://qy.xnjx.net/asr/';
        case 'scyb': // 宜宾正式版
            return 'ws://10.8.62.20:81/asr/';
        case 'scgz': // 甘孜正式版
            return 'ws://10.3.180.20:8001/asr/';
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return 'ws://10.2.64.221:8001/asr/';
        case 'scdz': // 达州正式版
            return 'ws://10.2.51.10:81/asr/';
        case 'scbz': // 巴中正式版
            return 'ws://10.2.6.48:81/asr/';
        case 'scsnj': // 省女子监狱
            return `ws://${host}/asr/`;
        case 'scmz': // 眉州监狱
            return 'ws://192.168.2.42:81/asr/';
        case 'newcall': // 通用配置
            return `${origin}/asr/`;
        default: // 默认
            return 'wss://qy.xnjx.net/asr/';
    }
}

/** 获取插话接口 */
function getInterjectUrl(version) {
    switch (version) {
        case 'develop': // 开发
            return 'http://paiyaowang.com:17754/';
        case 'trial': // 体验
            return '';
        case 'release': // 正式版
            return '';
        case 'scyb': // 宜宾正式版
            return '';
        case 'scgz': // 甘孜正式版
            return '';
        case 'scgz_bg': // 甘孜正式版(办公网络)
            return '';
        case 'scdz': // 达州正式版
            return '';
        case 'scbz': // 巴中正式版
            return 'http://10.2.6.46:6869/';
        case 'scsnj': // 省女子监狱
            return '/call/';
        case 'scmz': // 眉州监狱
            return 'http://192.168.2.9:6869/';
        case 'newcall': // 通用配置
            return '/aiswitch/';
        default: // 默认
            return 'http://paiyaowang.com:17754/';
    }
}

export default dev;
