import { defineStore } from 'pinia';
import req from '../api/index';

export const useFirstStore = defineStore('user', {
    state: () => {
        return {
            token: '',
            Num: '',
            recordingBaseUrl: '', // 录音基地址
            violationWordList: [], // 违规词列表
        };
    },
    getters: {},
    actions: {
        /** 获取录音地址 */
        async getRecordingBaseUrl() {
            try {
                const { code, data, msg } = await req.GetCallRecord({});
                if (code === 0) {
                    this.recordingBaseUrl = data;
                }
            } catch (error) {
                console.error(error);
            }
        },
        /** 获取违规词列表 */
        async getViolationWords() {
            try {
                const { code, data, msg } = await req.getViolationWordsApi();
                if (code === 0) {
                    this.violationWordList = data;
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
});

const jailerState = {
    id: '',
    name: '',
    jobName: '',
    loginID: '',
};

export const useJailerStore = defineStore('jailer', {
    state: () => {
        return jailerState;
    },
    actions: {
        setJailer(jailerInfo) {
            this.id = jailerInfo.LName;
            this.name = jailerInfo.Name;
            this.jobName = jailerInfo.JobName;
            this.loginID = jailerInfo.RecordID;
        },
    },
});
