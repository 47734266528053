import request from '@/common/request';
import config from '@/common/config';

const req = {
    //狱警登录
    login: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/ASRWebLogin', params);
    },
    // 民警话机保活
    keepAliveExtension: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/ASRKeepAlive', params);
    },
    // 修改密码
    updatePassword: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/ASRWebUpdatePwd', params);
    },
    //查询我的监室
    GetMyPoliceRoom: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/GetMyPoliceRoom', params);
    },
    // // 查询单个服刑人员所有记录
    // GetPrisonMsgAll: (params) => {
    //   return request.post(config.baseUrl + 'api/ShortMessage/GetPrisonMsgAll', params)
    // },
    // 新版吗查询单个服刑人员所有记录
    GetPrisonMsgAll: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetPrisonMsgAllNew', params);
    },

    //亲属审核
    Audit: (params) => {
        return request.post(config.baseUrl + 'api/Relatives/Audit', params);
    },
    // 短信审核
    smsAudit: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/Audit', params);
    },
    // 平信审核
    letterAudit: (params) => {
        return request.post(config.baseUrl + 'api/ElectronLetter/Audit', params);
    },
    //驳回惯用语
    GetDICTData: (params) => {
        return request.post(config.baseUrl + 'api/Relatives/GetDICTData', params);
    },

    //监室添加/移除服刑人员
    AddPrisonJS: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/AddPrisonJS', params);
    },

    //查询当前监室下的服刑人员
    GetPrisonPageList: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/GetPrisonPageList', params);
    },

    //查询所有服刑人员/模糊查询传Name
    GetAllPrisonPageList: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/GetAllPrisonPageList', params);
    },

    //查询所有监室接口
    GetJSPageList: (params) => {
        return request.post(config.baseUrl + 'api/PrisonRoom/GetJSPageList', params);
    },

    //模糊监室
    GetJSKeyWordPageList: (params) => {
        return request.post(config.baseUrl + 'api/PrisonRoom/GetJSKeyWordPageList', params);
    },

    //狱警添加监室接口
    Add: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/Add', params);
    },

    //移除监室
    Delete: (params) => {
        return request.post(config.baseUrl + 'api/PoliceRoom/Delete', params);
    },

    //查询狱警的监听记录
    GetUserRecordPageList: (params) => {
        return request.post(config.baseUrl + 'api/CallRecord/GetUserRecordPageList', params);
    },

    //一条通话记录的通话内容
    GetCallTextPageList: (params) => {
        return request.post(config.baseUrl + 'api/CallRecord/GetCallTextPageList', params);
    },

    // 一条通话记录的详情信息
    CallRecordInfo: (params) => {
        return request.post(config.baseUrl + 'api/CallRecord/CallRecordInfo', params);
    },

    // 查询服刑人员详情
    PrisonInfo: (params) => {
        return request.post(config.baseUrl + 'api/Prison/PrisonInfo', params);
    },

    // 监听文本质检接口
    QualityTestingAI: (params) => {
        return request.post(config.baseUrl + 'api/CallRecord/QualityTestingAI', params);
    },

    //查询亲属详情
    RelativesInfo: (params) => {
        return request.post(config.baseUrl + 'api/Relatives/RelativesInfo', params);
    },

    //查询服刑人员亲属
    WebRelativesList: (params) => {
        return request.post(config.baseUrl + 'api/Relatives/WebRelativesList', params);
    },

    //退出
    QuitLogin: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/QuitWebLogin', params);
    },

    //添加监听记录
    AddRecord: (params) => {
        return request.post(config.baseUrl + 'api/MonitorRecord/Add', params);
    },

    // 暂停/开始接入
    GetASRLoginOut: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/GetASRLoginOut', params);
    },

    //监听记录结束接口
    MonitorRecordOut: (params) => {
        return request.post(config.baseUrl + 'api/MonitorRecord/MonitorRecordOut', params);
    },

    // 删除电子平信附件
    DeleteFiles: (params) => {
        return request.post(config.baseUrl + 'api/ElectronLetter/DeleteFiles', params);
    },
    // 修改电子平信信件内容
    EditCotent: (params) => {
        return request.post(config.baseUrl + 'api/ElectronLetter/EditCotent', params);
    },

    // 修改电子平信信件内容
    SetCallQualityMan: (params) => {
        return request.post(config.baseUrl + 'api/CallRecord/SetCallQualityMan', params);
    },
    // 照片审核通过
    photoPassAudit: (params) => {
        return request.post(config.baseUrl + 'api/PhotoPrinting/Audit', params);
    },
    //座机号请求
    GetPCCodeAll: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/GetPCCodeAll', params);
    },
    // 定时请求token
    UpdateToken: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/UpdateToken', params);
    },
    //重置终端密码
    ResetPassword: (params) => {
        return request.post(config.baseUrl + 'api/Prison/ResetPassword', params);
    },
    //重置话机密码
    phonePassword: (params) => {
        return request.post(config.baseUrl + 'api/Prison/ResetTelPassword', params);
    },
    // 通话次数申请
    AddApplyPhoneSms: (params) => {
        return request.post(config.baseUrl + 'api/Prison/AddApplyPhoneSms', params);
    },
    //请求录音接口
    GetCallRecord: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/GetCallRecord', params);
    },
    //登录话机接口
    QuitPbxLogin: (params) => {
        // return request.post(config.baseUrl+'api/MonitorASR/QuitPbxLogin',params)
    },
    //退出话机接口
    QuitPbxLoginOut: (params) => {
        // return request.post(config.baseUrl+'api/MonitorASR/QuitPbxLoginOut',params)
    },
    SystemBasic: (params) => {
        return request.post(config.baseUrl + 'api/MonitorASR/SystemBasic', params);
    },
    // 视频通过接口
    VideoAudit: (params) => {
        return request.post(config.baseUrl + 'api/MonitorVideo/Audit', params);
    },
    // 获取监室列表
    getPrisonRoomList: () => {
        return request.post(config.baseUrl + 'api/PrisonRoom/GetJSPageList', {
            SearchKey: 'Prison_JSPageList',
        });
    },
    // 获取我的主管监室
    getMyPrisonRoom: () => {
        return request.post(config.baseUrl + 'api/PoliceRoom/GetMyPoliceRoom');
    },
    // 获取我的监室 待审消息
    getMyPrisonRoomReviewing: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetMyRoomMsgAll', params);
    },
    // 查询本监区下所有监室待审记录
    getAllPrisonRoomReviewing: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetJQReviewedMsgAll', params);
    },
    // 获取有关罪犯的所有审核消息
    getCriminalAllReviewInfo: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetPrisonMsgAllNew', params);
    },
    // 获取 已审核记录
    getAllReviewedRecord: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetMyReviewedMsgAll', params);
    },
    // 查询我的所有审核过的记录
    getMyReviewedRecord: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/GetMyAllReviewedMsgAll', params);
    },
    // 亲属绑定消息审核
    bindingReview: (params) => {
        return request.post(config.baseUrl + 'api/Relatives/Audit', params);
    },
    // 文本消息审核
    textReview: (params) => {
        return request.post(config.baseUrl + 'api/ShortMessage/Audit', params);
    },
    // 图片消息审核
    imageReview: (params) => {
        return request.post(config.baseUrl + 'api/PhotoPrinting/Audit', params);
    },
    // 视频消息审核
    videoReview: (params) => {
        return request.post(config.baseUrl + 'api/MonitorVideo/Audit', params);
    },
    // 电子书信消息审核
    emailReview: (params) => {
        return request.post(config.baseUrl + 'api/ElectronLetter/Audit', params);
    },
    // 单张图片审核驳回
    imageRejectReview: (params) => {
        return request.post(config.baseUrl + 'api/PhotoPrinting/AuditPothoFile', params);
    },
    // 单个视频审核驳回
    videoRejectReview: (params) => {
        return request.post(config.baseUrl + 'api/Video/AuditFile', params);
    },
    // 电子书信图片审核驳回
    emailImageRejectReview: (params) => {
        return request.post(config.baseUrl + 'api/ElectronLetter/DeleteFiles', params);
    },
    // 查询单张图片/视频的驳回意见
    queryRejectOpinion(params) {
        return request.post(config.baseUrl + 'api/ShortMessage/GetFileDetail', params);
    },
    // 获取驳回习惯语（驳回常用语）
    getIdioms: () => {
        return request.post(config.baseUrl + 'api/MonitorASR/GetDitcList', {
            SeachKey: 'Prison_SMS_Rejection',
        });
    },
    /** 获取违规词列表 */
    getViolationWordsApi: () => {
        return request.post(config.baseUrl + 'api/Keyword/GetKeywordAllList');
    },
    /** 获取日志更新记录 */
    getUpdateLogApi: (data) => {
        return request.post(config.baseUrl + 'api/AsrApiUpdateLog/GetUpdateLogPopupList', data);
    },
    /** 获取电话挂机后的备注惯用语 */
    getPhoneRemarkIdiomsApi: () => {
        return request.post(config.baseUrl + 'api/MonitorASR/GetDitcList', {
            SeachKey: 'PCMonitorIdiom',
        });
    },
};
export default req;
