import cofing from '@/common/config';

var webSocket;
// 推出接收数据
var onReceiveData;
var headSendPing = null;
var reconnectionNum = 0;
var urlRecord;
// 连接成功记录url
const socket = {
    connectSocket: (url, onReceive) => {
        if (typeof WebSocket === 'undefined') {
            return console.log('您的浏览器不支持websocket');
        }
        webSocket = new WebSocket(cofing.socket.wsUrl + url);
        // webSocket = new WebSocket("ws://10.8.62.20:81/asr/" + url);
        webSocket.onopen = socket.socketOpen;
        webSocket.onerror = socket.socketError;
        webSocket.onclose = socket.socketClose;
        webSocket.onmessage = socket.socketMessage;
        // 推出接收数据
        onReceiveData = onReceive;
        urlRecord = url;
    },
    // 连接成功
    socketOpen: (res) => {
        // 清空定时器
        socket.clearSocketPing();

        headSendPing = setInterval(function () {
            // 3次ping失败，则重新连接
            if (reconnectionNum == 3) {
                console.log('开始重连');
                // 重新连接
                socket.connectSocket(urlRecord, onReceiveData);
            }
            try {
                webSocket.send('ping');
            } catch (e) {
                console.log('ping失败:' + e.message);
            }
            reconnectionNum++;
        }, 1000 * 10);
    },
    // 接收到的消息
    socketMessage: (res) => {
        if (res.data == '连接成功') {
        } else if (res.data == 'ping') {
            reconnectionNum = 0;
        } else {
            onReceiveData(res);
        }
    },
    // 异常断开
    socketError: (res) => {
        console.log(res, 'socket异常断开了!');
        socket.connectSocket(urlRecord, onReceiveData);
    },
    // 关闭连接
    socketClose: (res) => {
        console.log(res, 'socketClose');
        // 清空定时器
        socket.clearSocketPing();
    },
    // 清空定时器
    clearSocketPing: () => {
        if (null != headSendPing) {
            clearInterval(headSendPing);
            headSendPing = null;
        }
    },
};
export default socket;
