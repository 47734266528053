import axios from 'axios';
import qs from 'qs';
import apiconfig from '@/common/config';
import { useFirstStore } from '../pinia';
import Vue from 'vue';
import router from '@/router';
// 配置开发环境还是生产环境
//axios.defaults.baseURL = process.env.NODE_ENV === "development"?'http//192.168.2.164:5001/':'http//192.168.2.164:5001/'

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const service = axios.create({
    //  baseURL:'http://192.168.2.164:5001',
    // apiURL:'http://192.168.2.31:8101',
    // withCredentials: true, // 跨域请求时发送cookie
    timeout: 5000, // 请求超时时间
    cancelToken: source.token,
});

service.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

service.interceptors.request.use((config, baseURL) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('TOKEN_KEY');
    config.data = qs.stringify(config.data); // 转为formdata数据格式
    return config;
});
// response响应拦截器  目的：遇到错误主动抛出，以便查看
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        // const token = sessionStorage.getItem("TOKEN_KEY")
        // console.log(token,'token');
        // 如果自定义代码不是200，则判断为错误
        if (res.code !== 0 && res.code !== 1002) {
            return Promise.reject(new Error(res.msg || 'Error'));
        } else if (res.code === 1002) {
            /** token 失效， 重新登录 */
            // 获取当前url 中的 code值
            const {
                currentRoute: {
                    query: { code },
                },
            } = router;
            /** 取消所有的请求 */
            source.cancel();
            sessionStorage.removeItem('TOKEN_KEY');
            router.replace(`/login?code=${code}`);
        } else {
            return res;
        }
    },
    (error) => {
        console.log(error.message);
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
    }
);
export default service;
